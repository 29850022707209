<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">

        <v-col v-if="viewStoricoBase == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/messages">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/storico-alertmsg.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">History</div>
                </template>
                <span>History</span>
            </v-tooltip>

        </v-col>



    </v-container>
</template>

<script>

import apitrial from "../utils/trial/apitrial";
import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();



        this.checkChangePwd();




        this.verifyTrialPeriod();


        this.$root.$children[0].totalMsgBaseRis = 0;
        this.$root.$children[0].totalMsgRefs = 0;


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");
        this.isAgila = window.$cookies.get("is_agila");

        if (a == 'Y' || a == 'S') {
            this.isAdmin = 1;
            this.isSU = 1;

        }


        if ((a == 'N') && (b == 'N')) {
            this.isEmployee = 1;
        }



        this.$root.$children[0].loadInfoTrial();
        this.$root.$children[0].loadLogo();


        if (this.isSU == 0) {

            this.viewNotifiche = this.getEnableConf("mng_not_alert");
            this.viewLogoFirma = this.getEnableConf("logo_firma_alert");
            this.viewModInvio = this.getEnableConf("mod_invio_alert");
            this.viewAziende = this.getEnableConf("azi_emt_alert");
            this.viewAziendeAgila = this.getEnableConf("azi_alert_alert");
            this.viewReferenti = this.getEnableConf("refs_alert");
            this.viewProfilo = this.getEnableConf("profilo_alert");
            this.viewUtenti = this.getEnableConf("users_alert");
            this.viewMsgBase = this.getEnableConf("msg_base_alert");
            this.viewMsgRisorse = this.getEnableConf("msg_ris_alert");
            this.viewStoricoBase = this.getEnableConf("sto_base_alert");
            this.viewMsgRefs = this.getEnableConf("msg_refs_alert");
            this.viewStoricoRefs = this.getEnableConf("sto_refs_alert");


        }
        else {

            this.viewNotifiche = 1;
            this.viewLogoFirma = 1;
            this.viewModInvio = 1;
            this.viewAziende = 1;
            this.viewAziendeAgila = 1;
            this.viewReferenti = 1;
            this.viewProfilo = 1;
            this.viewUtenti = 1;
            this.viewMsgBase = 1;
            this.viewMsgRisorse = 1;
            this.viewStoricoBase = 1;
            this.viewMsgRefs = 1;
            this.viewStoricoRefs = 1;

        }

        console.log("CHG PSW: ", this.isPwdChangedOnce);
        console.log("MSG BASE: ", this.viewMsgBase);

        this.getLastLogin();



    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },


    methods: {

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "alertmsg"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkChangePwd(
                atob(v_token)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        verifyTrialPeriod: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitrial.verifyTrial(
                v_token,
                "Alertmsg"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from verifyTrial", res);

                if (res.data.is_enable == "N") {



                    that.$root.$children[0].logout();


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },




    },


    data: () => ({

        isSU: 0,
        isPwdChangedOnce: 0,

        viewNotifiche: 0,
        viewLogoFirma: 0,
        viewModInvio: 0,
        viewAziende: 0,
        viewAziendeAgila: 0,
        viewReferenti: 0,
        viewProfilo: 0,
        viewUtenti: 0,
        viewMsgBase: 0,
        viewMsgRisorse: 0,
        viewStoricoBase: 0,
        viewMsgRefs: 0,
        viewStoricoRefs: 0,

        isEmployee: 0,


        isAgila: 0,
        isAdmin: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
